.v1-container {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.first-column {
    padding: 15px;
}

.second-column {
    padding: 15px;
}

.single-column {
    padding: 15px;
}

/* General Card Styling */
.card {
    margin-bottom: 20px;
    padding: 15px;
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .v1-container {
        padding: 10px;
    }
}