/* Top Navigation Bar */
.top-navigation-bar-container {
    background-color: #fff;
    padding: 0 10px;
}

.top-navigation-bar {
    width: 100%;
    padding: 10px 0;
    font-family: Arial, sans-serif;
    text-align: center;
    position: relative;
}

.nav-list {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
}

.nav-item {
    margin: 0 20px;
    font-size: 14px;
    color: #4b5563;
    cursor: pointer;
    font-family: 'Plus Jakarta Sans';
    position: relative;
    white-space: nowrap;
    display: inline-block;
    transition: color 0.3s ease, transform 0.3s ease;
    line-height: 35px;
    /* Ensures consistency with the icon height */
}

.nav-item:hover {
    color: #0071C2;
    transform: scale(1.05);
}

.nav-item.active {
    color: #0071C2;
    font-weight: 700;
}

/* Underline */
.underline {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: #0071C2;
    border-radius: 4px;
    width: 0;
    transition: all 0.3s ease;
    /* top: 35px; */
    /* Reduced from 48px to move the underline closer to the text */
}

/* Leaderboard Icon */
.leaderboard-icon .icon {
    width: 40px;
    /* Increased size */
    height: 40px;
    /* Increased size */
    margin-left: 5px;
    cursor: pointer;
}

.leaderboard-link {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Ensure spacing consistency */
.leaderboard-icon {
    line-height: 40px;
    /* Match line height with larger icon */
}

.leaderboard-icon:hover .icon {
    filter: brightness(0) saturate(100%) invert(29%) sepia(94%) saturate(2151%) hue-rotate(176deg) brightness(93%) contrast(101%);
}