.relevance-card {
    background-color: #eaf1f6;
    padding: 0px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
    
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 66px;
    padding: 16px 24px 16px 24px;
}

.card-header h3 {
    color: #006DBF;
    font-size: 24px;
    font-weight: 700;
    margin: 0;
}

.collapse-btn {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #0044cc;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.collapse-btn.collapsed {
    transform: rotate(180deg);
}

.text-section {
    padding: 16px 32px 0px 32px;
}

.text-section h5 {
    color: #333;
    font-size: 18px;
    /* margin-top: 20px; */
    font-weight: 600;
}

.text-section ul {
    padding-left: 20px;
}

.text-section ul li {
    margin-bottom: 10px;
    color: #555;
    font-size: 16px;
}

.graph-section {
    padding: 20px;
}

.graph-section .chart {
    margin-bottom: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .text-section {
        text-align: left;
    }

    .graph-section {
        margin-top: 20px;
    }
}