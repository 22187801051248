/* Account Container */
.account-container {
    display: flex;
    align-items: center;
    position: relative;
}

/* Login/Signup Link */
.login-signup-container {
    font-size: 14px;
    color: #4b5563;
    cursor: pointer;
    font-weight: 500;
    white-space: nowrap;
    position: relative;
    transition: color 0.3s ease, transform 0.3s ease;
}

.login-signup-container:hover {
    color: #0044cc;
    transform: scale(1.05);
}

/* Logged-in User Container */
.logged-in-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.user-dp {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #ddd;
    cursor: pointer;
}

/* Dropdown Menu */
.dropdown-menu {
    position: absolute;
    top: 50px;
    right: 0;
    background: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 10;
    padding: 10px;
    width: 150px;
}

.dropdown-item {
    padding: 8px 12px;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.dropdown-item:hover {
    background-color: #f5f5f5;
}

/* Underline */
.underline {
    position: absolute;
    height: 2px;
    background-color: #0044cc;
    border-radius: 4px;
    transition: all 0.3s ease;
    width: 0;
    bottom: -2px;
}

/* Login Popup */
.login-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    min-width: 300px;
    max-width: 500px;
    padding: 20px;
    z-index: 100;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    text-align: center;
}

.login-popup h2 {
    margin-bottom: 10px;
    font-size: 22px;
    color: #333;
}

.popup-tagline {
    font-size: 14px;
    color: #555;
    margin-bottom: 20px;
}

.social-button {
    width: 80%;
    margin: 10px 0;
    padding: 10px;
    border-radius: 6px;
    cursor: pointer;
    border: none;
    font-size: 16px;
    color: white;
}

.social-button.facebook {
    background-color: #4267B2;
}

.social-button.google {
    background-color: #DB4437;
}

.close-popup {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

/* Blur Background */
.blur-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
}