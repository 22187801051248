/* Ensure no padding or margin outside the card */
body,
html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.quiz-card {
    background-color: #fdecec;
    border: 1px solid #ff7f7f;
    border-radius: 12px;
    padding: 20px;
    max-width: 100%;
    /* Full width for responsiveness */
    margin: 0 auto;
    /* Center the card */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.quiz-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 10px;
}

.quiz-question {
    font-size: 1rem;
    font-weight: 400;
    color: #4b5563;
    margin-bottom: 20px;
}

.quiz-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.quiz-option {
    font-size: 1rem;
    font-weight: 400;
    color: #4b5563;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    transition: all 0.2s ease;
    cursor: pointer;
}

.quiz-option:hover {
    background-color: #ff7f7f;
    color: #fff;
}

.quiz-button {
    background-color: #e14d2a;
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: block;
    width: 100%;
    text-align: center;
}

.quiz-button:hover {
    background-color: #b83921;
}

/* Responsive Design */
@media (max-width: 768px) {
    .quiz-card {
        padding: 15px;
        /* Reduce padding on smaller screens */
    }

    .quiz-title {
        font-size: 1.2rem;
        /* Adjust title size */
    }

    .quiz-question {
        font-size: 0.95rem;
        /* Adjust question size */
    }

    .quiz-option {
        font-size: 0.95rem;
        /* Adjust option size */
        padding: 8px;
    }

    .quiz-button {
        font-size: 0.9rem;
        padding: 8px 15px;
        /* Adjust button padding */
    }
}