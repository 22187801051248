.tab-card {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 16px;
    background-color: #fff;
    font-family: Arial, sans-serif;
}

.selected-topics {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
    flex-wrap: wrap;
}

.topic-chip-wrapper {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    padding: 8px 12px;
    border-radius: 16px;
    font-size: 14px;
}

.topic-chip {
    margin-right: 8px;
}

.remove-button {
    background: none;
    border: none;
    color: #666;
    font-size: 14px;
    cursor: pointer;
    padding: 0;
}

.remove-button:hover {
    color: #ff4d4f;
}

.tab-navigation {
    display: flex;
    gap: 16px;
    border-top: 1px solid #e0e0e0;
    padding-top: 8px;
}

.tab {
    font-size: 14px;
    color: #555;
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 4px;
    transition: background-color 0.2s;
}

.tab:hover {
    background-color: #f0f0f0;
}

.tab.active {
    color: #007bff;
    font-weight: bold;
    border-bottom: 2px solid #007bff;
}