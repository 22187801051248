/* Header Container */
.header-container {
    width: 100%;
    background-color: #fff;
    padding: 10px;
    /* Add nice padding around all sides */
    margin: 0;
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* Add subtle drop shadow on the bottom */
    z-index: 50;
    /* Ensure header stays above other content */
}

/* Logo */
.logo {
    max-height: 40px;
    width: auto;
}

/* Search Bar Wrapper */
.searchbar-wrapper {
    width: 100%;
    margin: 0;
    padding: 0;
}

/* Top Navigation Bar */
.top-navigation-bar {
    width: 100%;
    background-color: #fff;
    border-bottom: none;
    margin: 0;
    padding: 0;
}

/* Ensure Clean Layout */
.row,
.col {
    margin: 0;
    padding: 0;
}

/* Line Removal */
.header-container::after,
.header-container::before,
.top-navigation-bar::after,
.top-navigation-bar::before {
    content: none;
}

/* Global Reset */
body,
html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}