/* SearchBar Container */
.searchbar-container {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 10px;
    height: 49px;
    width: 100%;
    margin: 0;
}

/* All and Search Flexbox */
.all-and-search {
    display: flex;
    align-items: stretch;
    gap: 0;
    width: 100%;
    
}

/* All Button Container */
.all-button-container {
    display: flex;
    align-items: center;
}

/* All Button */
.all-button {
    background-color: #dfeaf2;
    color: #111827;
    border: 0px solid #0044cc;
    padding: 0 15px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 10px 0 0 10px;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

.all-button:hover {
    background-color: #0171C3;
    color: #fff;
}

/* Down Arrow */
.down-arrow {
    margin-left: 5px;
}

/* Search Container */
.search-container {
    display: flex;
    align-items: center;
    flex-grow: 1;
    height: 49px;
    /* background-color: #fff; */
    border-radius: 10px;
}

/* Search Input */
.search-input {
    flex: 1;
    padding: 8px 10px;
    border: none;
    font-size: 14px;
    outline: none;
    background-color: transparent;
}

/* Disabled Input Style */
.disabled-style {
    background-color: #f5f5f5;
    color: #888;
    cursor: not-allowed;
}

/* Search Button */
.search-button {
    background-color: #0171C3;
    color: white;
    border: none;
    padding: 0 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 41px;
    border-radius: 10px;
    margin: 4px;
}

/* Lens Icon */
.lens-icon {
    width: 16px;
    height: 16px;
}

/* Dialog Box */
.dialog-box {
    position: absolute;
    top: 100%;
    left: 0;
    width: 406px;
    /* height: 368px; */
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    z-index: 10;
    display: flex;
    padding: 10px;
    gap: 10px;
}

/* Dialog Column */
.dialog-column {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.dialog-column h4 {
    font-size: 14px;
    margin-bottom: 10px;
    color: #333;
    width: 115px;
}

/* Year and Topic List */
.year-list,
.topic-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

/* Dialog Item */
.dialog-item {
    padding: 4px 6px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s ease, color 0.2s ease;
    background-color: transparent;
    color: #333;
}

.dialog-item:hover {
    background-color: #f5f5f5;
}

.dialog-item.active {
    background-color: #0174C5;
    color: white;
    font-weight: bold;
}

/* Scrollable Sections */
.year-list {
    max-height: 200px;
    overflow-y: auto;
}

.topic-list {
    max-height: 200px;
    overflow-y: auto;
}

/* Scrollbar Styling */
.year-list::-webkit-scrollbar,
.topic-list::-webkit-scrollbar {
    width: 5px;
}

.year-list::-webkit-scrollbar-thumb,
.topic-list::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 5px;
}

/* Suggestions List */
.suggestions-list {
  position: absolute;
  /* top: calc(100% + 5px); Space below input field */
  top: 100%;
  left: 0;
  width: 100%; /* Match the input field width */
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 0px 0px 10px 10px !important;  
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  z-index: 2000; /* Ensure it appears above other elements */
  max-height: 200px;
  overflow-y: auto;
}

/* Search Input Wrapper */
.search-input-wrapper {
  position: relative; /* Position relative for the suggestions list */
}

/* Suggestion Item */
.suggestions-list .list-group-item {
  cursor: pointer;
  font-size: 14px;
}

.suggestions-list .list-group-item:hover {
  background-color: #f5f5f5;
}

.suggestions-list .list-group-item {
    cursor: pointer;
    pointer-events: auto;
}
