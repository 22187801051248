/* CurrentAffairsCard.css */
.current-affairs-container {
    width: 100%;
    background-color: #f9f9f9;
    padding: 1rem;
    margin-top: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section-title {
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 1rem;
}

.news-list {
    overflow-y: auto;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.news-card {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    border-radius: 8px;
    padding-left: 1rem;
    padding-right: 1rem;
    /* background-color: #fff; */
    /* transition: box-shadow 0.3s ease; */
}

.news-card:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.news-header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.news-logo {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
}

.news-source {
    font-size: 14px;
    color: #C64141;
    font-family: 'Plus Jakarta Sans';
    /* font-weight: bold; */
}

.news-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.news-text {
    flex: 1;
}

.news-title {
    font-size: 16px;
    font-family: 'Plus Jakarta Sans';
    /* font-weight: 50; */
    color: #101828;
    margin: 0;
}

.news-time {
    font-size: 12px;
    color: #475467;
    margin-top: 0.25rem;
}

.thumbnail-image {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    object-fit: cover;
}

@media (max-width: 767px) {
    .news-content {
        flex-direction: column;
        align-items: flex-start;
    }

    .thumbnail-image {
        width: 100%;
        height: auto;
        margin-top: 0.5rem;
    }
}