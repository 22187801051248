/* General Styling */
.mcq-card {
  margin-top: 10px;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: "Arial", sans-serif;
  background-color: #F5FFF8;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 66px;
  padding: 16px 24px 16px 24px;
}

.card-header h3 {
  font-size: 1.5rem;
  color: #55A2D8;
}

/* .questions-container {
  border-top: 10px; 
} */

/* Question Card */
.question-card {
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 12px;
  background-color: #F5FFF8;
  border: 1px solid #cce6cc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.question-text {
  font-size: 16px;
  color: #003366;
  margin-bottom: 12px;
}

/* Options */
.options-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.option {
  font-size: 14px;
  padding: 10px;
  margin-bottom: 8px;
  border: 1px solid #cce6cc;
  border-radius: 8px; /* Curvy corners for options */
  background-color: #F5FFF8;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.option:hover {
  background-color: #dff4df;
}

.option.correct-answer {
  background-color: #dff0d8;
  border-color: #4caf50;
}

.option.incorrect-answer {
  background-color: #f8d7da;
  border-color: #f44336;
}

/* Feedback Buttons */
.feedback-container {
  margin-top: 12px;
  display: flex;
  gap: 16px; /* Proper spacing between buttons */
  flex-wrap: wrap;
}

.outline-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: transparent;
  color: #0044cc;
  border: 1px solid #0044cc;
  border-radius: 8px; /* Rounded corners */
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.outline-button .icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border: 1px solid #0044cc;
  border-radius: 4px;
}

.outline-button.active-feedback {
  color: #0044cc;
  border-color: #0044cc;
  background-color: transparent;
}

.outline-button.active-feedback .icon {
  background-color: #0044cc;
  color: #fff;
}

/* Accordion */
.accordion-box {
  border: 1px solid #cce6cc;
  border-radius: 8px;
  background-color: #F5FFF8;
  padding: 12px;
  margin-top: 16px;
  cursor: pointer;
}

.accordion-box.expanded {
  padding: 20px;
}

.accordion-content {
  margin-top: 10px;
  font-size: 14px;
}

/* Show More Button */
.show-more {
  background-color: #0044cc; /* Solid button with blue background */
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  display: block;
  margin: 24px auto; /* Center the button horizontally */
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.show-more:hover {
  background-color: #003bb5;
}

.show-more:active {
  background-color: #b3d7ff;
  color: #fff;
}

/* Reference Tags */
.reference-tags {
  display: flex;
  gap: 10px;
  margin-top: 16px;
  flex-wrap: wrap;

}

.tag-item {
  display: flex;
  align-items: center;
  height: 25px;
  gap: 8px;
  padding: 5px 16px;
  background-color: rgba(0, 113, 194, 0.01);;
  border-radius: 9px;
  /* Curved design */
  border: 1px solid #b3e0ff;
  font-size: 14px;
  color: #0071C2;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.tag-item:hover {
  background-color: #007acc;
  border-color: #005999;
  color: #ffffff;
}

.tag-item:hover svg path {
  stroke: #ffffff;
  /* Makes the PlusIcon white */
}

.tag-text {
  font-size: 16px;
  font-weight: 500;
}

.tag-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: #007acc;
  color: #ffffff;
  border-radius: 50%;
  /* Circle */
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.tag-item:hover .tag-icon {
  background-color: #ffffff;
  color: #007acc;
}

.question-container {
  display: block;
  /* Ensures the number and text are treated as a single block */
  margin-bottom: 10px;
  /* Adds space between questions */

}

.question-number {
  font-weight: bold;
  display: inline-block;
  /* Keeps the number inline with the first line of text */
  width: 40px;
  /* Fixed width for consistent spacing */
}

.question-text {
  display: inline;
  color: #212121;
  font-size: 18px;
  /* Ensures the text starts inline with the question number */
  margin: 0;
  padding-left: 0;
  /* Ensures no padding */
  line-height: 1.4;
  /* Adjust line spacing */
}